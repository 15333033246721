
<layout-sidebar-wrapper>
	<div class="content">
		<div class="content-inner">
			<router-outlet-fullscreen></router-outlet-fullscreen>
		</div>
	</div>
	
	<layout-sidebar [menuModel]="menuModel" [expandAll]="true">
		<h4 class="title">Verwaltung</h4>
	</layout-sidebar>
</layout-sidebar-wrapper>
	