<pflegetheke-layout-frontpage [showBottomButtons]="!(loggedIn$|async)" >
	<feedback-button class="top-menu-item"  buttonClass=""></feedback-button>	

	<div class="content-left">
		<div class="cover-image"></div>
		<video [autoplay]="true" [muted]="true" muted playsinline preload="metadata" class="cover-image" #video [@OutAnimation] *ngIf="videoVisible">
			<source type="video/mp4" src="https://res.cloudinary.com/pflegetheke/video/upload/v1668603494/pflegetheke/general/Pflegetheke_Header_1200x1200_i78ojs.mp4">
		</video>

		<button uiButton [icon]="muteState ? 'volume-mute' : 'volume-up'" [title]="muteState ? 'Ton einschalten' : 'stummschalten'" 
			class="button-umute ui-button-outline" (click)="toggleVideoMuteState()"></button>

	</div>
	<div class="content-right">

		<div *ngIf="loggedIn$|async; else guestView" e2e-landingpage-logged-in>
			<h2>Willkommen zurück!</h2>
			<p>Was möchten Sie tun?</p>

			<div class="usermenu">
				<a class="ui-button-outline" [routerLink]="'profile'|resolveRouterLink" iconPos="left" uiButton icon="user" label="Mein Profil" ></a>
				<a class="ui-button-outline" [routerLink]="'questionCategories'|resolveRouterLink" iconPos="left" uiButton icon="chalkboard-teacher" label="Themenübersicht"></a>
				<a class="ui-button-outline" [routerLink]="'logout'|resolveRouterLink" iconPos="left" uiButton icon="lock" label="Logout"></a>
			</div>

		</div>

		<ng-template #guestView>
			<div>
				<h2>
					Willkommen bei der <b class="color-primary">Pflegetheke!</b><br>
				</h2>
				<p >
					Es passiert unerwartet: Eine Angehörige oder ein Angehöriger kann den Alltag nicht mehr alleine bewältigen.
					Man steht plötzlich vor einer gänzlich unbekannten Aufgabe.
					Man muss sich viel neues Wissen aneignen - eine Herausforderung neben Alltag und Pflege/Unterstützung der betroffenen Person.
				</p>
				<h2>
					Wir helfen <b class="color-primary">Ihnen.</b>
				</h2>
				<p >
					In unseren kompakten Videoserien finden Sie alle wichtigen Informationen zu Themen wie z.B.:
				</p>
			</div>
			<br>
			<ul class="ui-iconlist medium">
				<li><ui-icon icon="check"></ui-icon> Leistungsansprüche</li>
				<li><ui-icon icon="check"></ui-icon> Antragsverfahren und Pflegekasse</li>
				<li><ui-icon icon="check"></ui-icon> Der richtige Umgang mit bestimmten Krankheiten</li>
				<li><ui-icon icon="check"></ui-icon> Hilfsmittel und Versorgung</li>
				<li><ui-icon icon="check"></ui-icon> Insgesamt Antworten auf über 150 Fragen!</li>
			</ul>
			<div>
				<h2>
					Unser <b class="color-primary">Qualitätsanspruch</b>
				</h2>
				<br>
				<ul class="ui-iconlist medium">
					<li><ui-icon icon="check"></ui-icon> Dozenten mit langjährigem Fachwissen</li>
					<li><ui-icon icon="check"></ui-icon> Unsere Experten schulen auch Fachpersonal </li>
				</ul>
				<a class="ui-button-outline" [routerLink]="'questionCategories'|resolveRouterLink" iconPos="right" uiButton icon="arrow-right" label="Jetzt Videos ansehen"></a>
			</div>
		
		</ng-template>

	</div>
</pflegetheke-layout-frontpage>