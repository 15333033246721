import { Component } from '@angular/core';
import { AccessState } from '@core/frontend-shared';
import { Select } from '@ngxs/store';
import * as deepmerge from 'deepmerge';
import { FeatureMenuItem } from '@core/frontend-shared/feature';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutoUnsubscribe } from '@core/frontend-shared/utils';
import { FeatureMenuItemService } from '@core/frontend-shared/feature';
import { RouterLinkResolverService } from '@core/shared/feature';

@Component({
	selector: 'pflegetheke-toolbar',
	styleUrls:['./toolbar.component.scss'],
	template: `
	<ui-tabMenu [model]="menu$|async" [activeItem]="activeItem">
	<ng-template uiTemplate="item" let-item let-i="index" let-selected="selected">

		<div class="content" >
			<ui-icon [ngClass]="item.icon" *ngIf="item.icon && item.id!=='notifications'" [icon]="item.icon" size="2x"></ui-icon>
			<notifications-info-icon *ngIf="item.id==='notifications'"></notifications-info-icon>
			<div class="label">
				{{item.label}}
			</div>
		</div>

	</ng-template>
	</ui-tabMenu>
	`
})
@AutoUnsubscribe()
export class AppToolbarcomponent {

	@Select(AccessState.loggedIn) loggedIn$!:Observable<boolean>;
	@Select(AccessState.isSuperUser) isSU$!:Observable<boolean>;
	@Select(AccessState.accessData('isManager')) isManager$!:Observable<boolean>;

	protected _defaultMenu:FeatureMenuItem[] = [
		{ label: 'Anmelden', icon:'unlock', routerLink:this.resolver.resolveRouterLink('frontend','login'), id:'login' },
		{ label: 'Mein Profil', icon:'user', routerLink:this.resolver.resolveRouterLink('frontend','profile'), id:'profile' },
		// { label: 'Management', icon:'user', routerLink:this.resolver.resolveRouterLink('frontend','management'), id:'management', visible:false },		
		{ label: 'Themen', icon:'chalkboard-teacher', routerLink:this.resolver.resolveRouterLink('frontend','questionCategories') },
		// { label: 'Neues', icon:'bell', routerLink:this.resolver.resolveRouterLink('frontend','notifications'), id:'notifications' },
		{ label: 'Hilfe', icon:'question-circle', routerLink:this.resolver.resolveRouterLink('frontend','faq') },
		{ label: 'Menu', icon:'bars', items:[
			{ label: 'Datenschutz', icon:'user-shield', routerLink:'privacy' },
			{ label: 'Impressum', icon:'info-circle', routerLink: 'imprint' },
			{ label: 'Logout', icon:'lock', routerLink:this.resolver.resolveRouterLink('frontend','logout'), id:'logout' },
		] },
	];

	menu$:Observable<FeatureMenuItem[]>

	// get menu() {
	// 	return this._defaultMenu
	// }

	activeItem = 0;
	
	constructor(
		featureMenuItemService:FeatureMenuItemService,
		protected resolver:RouterLinkResolverService,
	) {
		this.menu$ = combineLatest([
			featureMenuItemService.hasAccessibleMenuItems('managementToolbar'),
			this.loggedIn$,
			this.isManager$,
			this.isSU$,
		]).pipe(map(([hasManagerMenuItems,loggedIn,isMgr,isSU])=>{
			const m = deepmerge([],this._defaultMenu);

			findMenuItemDeepById(m,'login').visible = !loggedIn;
			findMenuItemDeepById(m,'profile').visible = loggedIn;
			findMenuItemDeepById(m,'logout').visible = loggedIn;
			return m
		}))

	}

}


function findMenuItemDeepById(menuItems:FeatureMenuItem[], id:string) {
	for(const menuItem of menuItems) {
		if(menuItem.id === id) return menuItem;
		if(menuItem.items) {
			const result = findMenuItemDeepById(menuItem.items,id);
			if(result !== false) return result;
		}
	}
	return false;
}


