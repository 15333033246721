import { Component } from '@angular/core';
import { ConfigService } from '@core/shared/config';

@Component({
	selector: 'app-imprint',
	styles:[`:host {display:block; padding:20px; } svg {height:100%; max-width:100%;}`],
	template: `<ui-card size="block">
		<cms-module-position position="imprint" [context]="moduleContext"></cms-module-position>
		<p>
			Installierte App Version: {{currentVersion}}
		</p>
		<h1>Lizenzen von Drittanbietern</h1>
		<a href="/3rdpartylicenses.txt" uiButton >Öffnen</a>
	</ui-card>
	`
})
export class AppImprintComponent {

	contactEmail:string;
	contactPhone:string;
	currentVersion:string;

	moduleContext:any;

	constructor(
		config:ConfigService
	) {
		this.moduleContext = {
			contactEmail:config.get('contact.mail',null),
			contactPhone:config.get('contact.phone',null),
			businessHours:config.get('contact.businessHours',null),
		}
		this.currentVersion = config.get('version',null);
	}

}




